import React from 'react';
import { connect } from "react-redux";
import { useParams , useNavigate  } from 'react-router-dom';
import { useEffect, useCallback  } from "react";
import * as config from "../../assets/js/constant.js";
import { useImmer } from 'use-immer';
import useRazorpay from "react-razorpay";
import axios from 'axios';
import { 
  Box, 
  Button, 
  Container, 
  Divider, 
  Grid, 
  Step, 
  StepContent, 
  StepLabel, 
  Stepper, 
  Typography,
  CircularProgress
  
} from '@mui/material';
import '../MyDonationComponent/MyDonationComponent.css';

import HeaderComponent from '../../common/HeaderComponent/HeaderComponent';
import MakeMyDonationComponent from '../MakeMyDonationComponent/MakeMyDonationComponent';
import ConfirmMyDonationComponent from '../ConfirmMyDonationComponent/ConfirmMyDonationComponent';
import DonationPaymentDetailsComponent from '../DonationPaymentDetailsComponent/DonationPaymentDetailsComponent';
import FooterComponent from '../../common/FooterComponent/FooterComponent';
import BottomAppBarComponent from '../../common/BottomAppBarComponent/BottomAppBarComponent';
import {getDonationdetails} from '../../store/actions/donation.js';
import {setDonationbookingDetails} from '../../store/actions/donation.js';
import {setDonationPaymentDetails} from '../../store/actions/donation.js';
import {donationPaymentProcess} from '../../store/actions/donation.js';
import {donationComplete} from '../../store/actions/donation.js';




  const MyDonationComponent = ({
    donationPaymentProcess,
    getDonationdetails,
    donationDetails,
    setDonationbookingDetails,
    donationBookingDetails,
    donationComplete

  }) => {

     const navigate = useNavigate();
     const DONATIONDETAILSVALUE = {
      entity : {value: "DONATION"},
      donationID : {value : donationDetails.DonationID},
      donationName : {
        value: donationDetails.DonationName,
      },
      Amount : {
        value:  "",
        isValid:false,
        errorMesssage:"Please enter Donation Amount"
      },
      donationUserName : {
        value:"",
        isValid: false,
        errorMessage:"User name not valid!!"
      },
      donationUserContact : {
        value:"",
        isValid: false,
        errorMessage:"User Contactnumber not valid!!"
      },
      donationUserAlt : {
        value:"",
        isValid: true,
        errorMessage:"User Alternatenumber not valid!!"
      },
      // donationemailName : {
      //   value:"",
      //   isValid: true,
      //   errorMessage:"Email name not valid!!"
      // },
      donationemailAddress : {
        value:"",
        isValid: true,
        errorMessage:"Email address not valid!!"    
      },
      donationemailPincode : {
        value:"",
        isValid: true,
        errorMessage:"Email Pincode not valid!!"  
      } 

     }

    const DONATIONPAYMENTVALUES = {
    
    donationUserEmail : {
        value : "",
        isValid : false,
        errorMessage : "Please enter valid email"
      },
      userComments : {
        value : "",
        isValid : true,
        errorMessage : "Comments should not exceed 120 characters." 
      },
      OrderID : {
        value : "",
      },
    }


    const [getuserDonationdetails,setuserDonationdetails] = useImmer(DONATIONDETAILSVALUE);
    const [getDonationPaymentDetails,setDonationPaymentDetails] = useImmer(DONATIONPAYMENTVALUES);
    const [getloader,setloader] =  useImmer(false);
    const [activeStep, setActiveStep] = useImmer(0);

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  console.log(getuserDonationdetails)
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEdit = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleConfirm = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

const steps = [
  {
    label: 'Make A Donation',
    MakeDonation: <MakeMyDonationComponent getuserDonationdetails = {getuserDonationdetails} setuserDonationdetails={setuserDonationdetails}/>,
    nextStep: function() {
      if (
        getuserDonationdetails.Amount.isValid &&
        getuserDonationdetails.donationUserName.isValid &&
        getuserDonationdetails.donationUserContact.isValid &&
        getuserDonationdetails.donationUserAlt.isValid &&
        // getuserDonationdetails.donationemailName.isValid &&
        getuserDonationdetails.donationemailAddress.isValid &&
        getuserDonationdetails.donationemailPincode.isValid
      ) {
        const valuesOnly = Object.keys(getuserDonationdetails).reduce((acc, key) => {
          acc[key] = getuserDonationdetails[key].value;
          return acc;
        }, {});
    
        console.log("valuesOnly:", valuesOnly);
        setDonationbookingDetails(valuesOnly);
        handleNext();
      }
    },
    buttons: ['Next']
  },
  {
    label: 'Confirmation',
    DonationConfirmation: <ConfirmMyDonationComponent/>, 
    nextStep : function(){
      setloader(true);
      handlePayment(donationBookingDetails);
      console.log(donationBookingDetails)
    },
    buttons: ['Edit','PayNow']
  },
  {
    label: 'Payment Details',
    PaymentDetails: <DonationPaymentDetailsComponent getDonationPaymentDetails = {getDonationPaymentDetails} setDonationPaymentDetails = {setDonationPaymentDetails} Amount = {getuserDonationdetails.Amount.value} />,
    nextStep : function(){
      if(
        getDonationPaymentDetails.donationUserEmail.isValid &&
        getDonationPaymentDetails.userComments.isValid
      ) {
        const valuesOnly = Object.keys(getDonationPaymentDetails).reduce((acc, key) => {
          acc[key] = getDonationPaymentDetails[key].value;
          return acc;
        }, {});
        console.log(getDonationPaymentDetails);
        console.log("VALUESONLY FOR DONATIONPAYMENT:",valuesOnly);
        donationComplete(valuesOnly);
        navigate("/donation-success", {state: {donationUserEmail : getDonationPaymentDetails.donationUserEmail.value}}); 
    
      }

    },
    buttons: ['Submit']
  },
];



const renderButtons = (step) => {
  return step.buttons.map((button, index) => {
    switch (button) {
      case 'Back':
        return (
          <Button sx={{ mt: 1, mr: 1, color: 'grey', borderColor: 'grey !important' }} variant="outlined" key={index} onClick={handleBack}>
            Back
          </Button>
        );
      case 'Next':
        return (
          <Button sx={{ mt: 1, mr: 1 }} variant="contained" key={index} onClick={step.nextStep}>
            Next
          </Button>
       );
       case 'Submit':
         return (
           <Button sx={{ mt: 1, mr: 1 }} variant="contained" key={index} onClick={step.nextStep}>
             Submit
           </Button>
         );
       case 'Edit':
         return ( 
           <Button sx={{ mt: 1, mr: 1, color: 'grey', borderColor: 'grey !important' }} variant="outlined" key={index} onClick={handleEdit}>
             Edit
           </Button>
         );
       case 'PayNow':
         return (
           <Button sx={{ mt: 1, mr: 1 }} variant="contained" key={index} onClick={step.nextStep}>
             Pay Now
           </Button>
         );
       default:
         return null;
     }
   });
 };


 const {DonationID} = useParams();

 useEffect(() => {
   getDonationdetails(DonationID);
 }, [DonationID])

 
useEffect(() => {
  setuserDonationdetails((draft) => {
      draft.donationID.value = donationDetails.DonationID;              /*IF DonationID,DonationName,Amount shows undefined can uncomment it*/ 
      draft.donationName.value = donationDetails.DonationName;
      draft.Amount.value = donationDetails.Amount;
  })

}, [donationDetails]);


// useEffect(() => {
//   if (donationDetails) {
//     // Safely update only after donationDetails is available
//     setuserDonationdetails(draft => {
//       draft.donationID.value = donationDetails.DonationID || "";
//       draft.donationName.value = donationDetails.DonationName || "";
//       draft.Amount.value = donationDetails.Amount || 0;
//     });
//   }
// }, [donationDetails, setuserDonationdetails]);
 
const [Razorpay] = useRazorpay();

const handlePayment = useCallback((donationBookingDetails) => {
  axios.post(config.API_BASE_URL+'apiCall.php?apicall=donationOrderCreation',
{data : donationBookingDetails })
.then(response => {
  var data = response.data.Data;
  console.log(data);
  setDonationPaymentDetails((draft)=>{
    draft.OrderID.value = data.OrderID;
})

  const options = {
    key: data.key_id,
    amount: data.Amount,
    currency: data.Currency,
    name: "NSR Trust",
    description: `Booking for Donation ${getuserDonationdetails.donationName}`,
    image: "https://sridevitemple.com/assets/temple-images/god-image.jpg",
    order_id:  data.OrderID,
    "method": {
      "netbanking": 1,
      "card": 1,
      wallet: 1,
      upi: 1
    },
    handler: (res) => {  
      donationPaymentProcess(res);
      handleNext();
      setloader(false);
    },
    prefill: {
    name : donationBookingDetails.donationUserName,
    contact : donationBookingDetails.donationUserContact
    },
    notes: {
      address: "SSRA, Nanganallur",
    },
    theme: {
      color: "#3399cc",
    },
  };

  console.log(options);

  const rzpay = new Razorpay(options);

  rzpay.on("payment.failed", function (response) {
    alert(response.error.description);
  });
  rzpay.open();
  setloader(false)
})
.catch(error => console.log(error));
}, [Razorpay]);



  return (
    <>
        <HeaderComponent />
        <Box sx={{ mt: 3 }}>
          <Container>
            <Grid container spacing={1} sx={{ mb: 2 }}>
              <Grid item xs={12} md={5}>
                <Box className="user-selected-donation" sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}>
                    <img
                        className='donation-image'
                        src={config.image+donationDetails.Image}
                        // src={String(DashaDanam)}
                        // src={DashaDanam}
                        alt="donationImage"
                    />
                </Box>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box>
                  <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#3875BF', mb: 2 }}>
                   {donationDetails.DonationName}
                  </Typography>
                  <Typography sx={{ lineHeight: 2.3, color: '#8E8E8E' }}>
                    {donationDetails.Description}
                    {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. */}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {/* <hr className='horizontal-line' /> */}
            <Divider />
          </Container>
        </Box>
        <Box className="make-donation-screen">
            <Container>
                <Box sx={{ py: 2 }}>
                  <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                        <Step key={step.label} >
                          <Box className="stepper-label">
                            <StepLabel>
                              {step.label}
                            </StepLabel>
                          </Box>
                            <StepContent>
                                <Typography>{step.description}</Typography>
                                {step.MakeDonation}
                                {step.DonationConfirmation}
                                {step.PaymentDetails}
                                {steps[activeStep] && (
                                <Box className="stepContent-btn" sx={{ mb: 2, textAlign: 'right', mx: 20 }}>
                                   { getloader ? (<Box sx={{ mt: 1, mr: 1 }} variant="contained"><CircularProgress /></Box>) : renderButtons(steps[activeStep]) }
                                   </Box>
                                  )}
                                      {/* {
                                        ()=>{
                                          if(!index === 0){
                                            <Button
                                              disabled={index === 0}
                                              variant="outlined"
                                              onClick={handleBack}
                                              sx={{ mt: 1, mr: 1 }}
                                          >
                                              Back
                                          </Button>
                                          }
                                        }
                                      } */}
                                        {/* <Button
                                            disabled={index === 0}
                                            variant="outlined"
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                          Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                          {index === steps.length - 1 ? 'Submit' : 'Next'} */}
                                        {/* </Button> */}
                                
                                {/* <hr className='horizontal-line' /> */}
                                <Divider />
                            </StepContent>
                        </Step>
                        ))}
                  </Stepper>
                </Box>
            </Container>
            <FooterComponent />
        </Box>
        <BottomAppBarComponent />
    </>
  )
}


const mapStateToProps = (state) => {
return {
  donationDetails : state.donation.donationDetails,
  donationBookingDetails : state.donation.donationBookingDetails
}
}

export default connect(mapStateToProps,{
  donationPaymentProcess,
  getDonationdetails,
  setDonationbookingDetails,
  setDonationPaymentDetails,
  donationComplete


})(MyDonationComponent);
