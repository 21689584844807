import { apiRequest } from "../../actions/api";

import{

    GETDONATION,
    GETDONATION_SUCCESS,
    GETDONATION_ERROR,
    getDonationSuccess,
    getDonationError,
    setDonation,

    GETDONATIONDETAILS,
    GETDONATIONDETAILS_SUCCESS,
    GETDONATIONDETAILS_ERROR,
    getDonationdetailsSuccess,
    getDonationdetailsError,
    setDonationdetails,

    GETPAYMENT,
    GETPAYMENTSUCCESS,
    GETPAYMENTERROR,
    getDonationPaymentDetailsSuccess,
    getDonationPaymentDetailsError,
    setDonationPaymentDetails,

    DONATIONPAYMENTPROCESS,
    DONATIONPAYMENTSUCCESS,
    DONATIONPAYMENTERROR,
    donationPaymentProcess,
    donationPaymentSuccess,
    donationPaymentError,

    DONATIONCOMPLETE,
    DONATIONCOMPLETESUCCESS,
    DONATIONCOMPLETEERROR,
    donationComplete,
    donationCompleteSuccess,
    donationCompleteError






}from "../../actions/donation";

export const getalldonation = ({ dispatch }) => (next) => (action) => {
    console.log("Donation list")
    next(action);
    if (action.type === GETDONATION) {
        dispatch(
            apiRequest(
                "GET",
                "apiCall.php?apicall=donationList",
                action.payload,
                null,
                getDonationSuccess,
                getDonationError,
                false,
                "['Donation']"
            )
        );

    }
};

export const progressdonation = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GETDONATION_SUCCESS) {
        dispatch(setDonation(action.payload.data));
    }

    if (action.type === GETDONATION_ERROR) {

    }
}


export const getalldonationdetails = ({ dispatch }) => (next) => (action) => {

    next(action);

    if(action.type === GETDONATIONDETAILS) {
        dispatch(
            apiRequest(
                "GET",
                "apiCall.php?apicall=donationDetails&DonationID=" + action.payload,
                null,
                null,
                getDonationdetailsSuccess,
                getDonationdetailsError,
                false,
                "['DonationDetails']"
            )
        )
    }

}

export const progressDonationDetails = ({dispatch}) => (next) => (action) => {

    
    next(action);

    if(action.type === GETDONATIONDETAILS_SUCCESS) {
        var data = action.payload.data[0];
        console.log(data);
        dispatch(setDonationdetails(data));

    }

    if( action.type === GETDONATIONDETAILS_ERROR) {

    }

}

export const getDonationPaymentDetails = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GETPAYMENT) {
        dispatch(
            apiRequest(
                "GET",
                "apiCall.php?apicall=donationPaymentDetails",
                action.payload,
                null,
                getDonationPaymentDetailsSuccess,
                getDonationPaymentDetailsError,
                false,
                ['PAYMENTDETAILS']
            )
        )
    }

}

    export const setDonationPayment = ({ dispatch }) => (next) => (action) => {
        next(action);

            if (action.type === GETPAYMENTSUCCESS) {
                var data = action.payload.data;
                dispatch(setDonationPaymentDetails(data));
                console.log("PAYMENT SUCCESS");
            }
            if (action.type === GETPAYMENTERROR) {
                console.log("PAYMENTDETAILS FAILED");
            }

    }

    export const donationProcess = ({ dispatch }) => (next) => (action) => {
        next (action);

        if (action.type === DONATIONPAYMENTPROCESS) {
            dispatch(
                apiRequest(
                    "POST",
                    "apiCall.php?apicall=donationPayment",
                    null,
                    action.payload,
                    donationPaymentSuccess,
                    donationPaymentError,
                    false,
                    ['PAYMENTDETAILS']
                )
            )
        }
    }

    export const donationPayment = ({ dispatch }) => (next) => (action) => {
        next(action);
    
        if (action.type === DONATIONPAYMENTSUCCESS) {
            var data = action.payload.data;
            dispatch((data));
            console.log("DONATIONPAYMENT CAME TO SUCCESS");
        }
        if (action.type === DONATIONPAYMENTERROR) {
            console.log("DONATIONPAYMENT FAILED");
        }
    }

    export const donationFlow = ({dispatch}) => (next) => (action) => {
        next(action);

        if (action.type === DONATIONCOMPLETE) {
            dispatch(
                apiRequest(
                    "POST",
                    "apiCall.php?apicall=donationPaymentCreation",
                    null,
                    action.payload,
                    donationCompleteSuccess,
                    donationCompleteError,
                    false,
                    ['DONATIONPAYMENTDETAILS']
                )
            )
        }
    }

    export const donationFlowProcess = ({dispatch}) => (next) => (action) => {
        next(action);
    
        if (action.type === DONATIONCOMPLETESUCCESS) {
    
        }
    
        if (action.type === DONATIONCOMPLETEERROR) {
        }
    }
    

export const donationmodel = [
    getalldonation,
    progressdonation,
    getalldonationdetails,
    progressDonationDetails,
    getDonationPaymentDetails,
    setDonationPayment,
    donationProcess,
    donationPayment,
    donationFlow,
    donationFlowProcess

]