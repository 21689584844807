
import {pujamodel} from "./Puja.js";
import {donationmodel} from "./Donation.js";


export default function getFeatureMiddleware() {
  return [
    ...pujamodel,
    ...donationmodel
  ]
}