
import React from 'react';
import { 
  Box, 
  Typography 
} from '@mui/material';

import { Link } from 'react-router-dom';
import '../FooterComponent/FooterComponent.css';

export default function FooterComponent() {
  return (
    <>
        <Box sx={{ my: 4, pb: 1 }}>
          <Box className = "copyrights"   
                      sx={{
                      textAlign: 'center',
                      pb: 2,
                      // display: 'flex',
                      // flexDirection: { xs: 'column', sm: 'row' }, // Column on small screens, row on larger screens
                      alignItems: 'center',
                      justifyContent: 'center',
                      }} >
              <Link to="/aboutus" > About us</Link> |
              <Link to="/terms" > Terms & Conditions</Link> |
              <Link to="/contactus" > Contact us</Link> |
              <Link to="/privacy" > Privacy policy</Link> |
              <Link to="/refund" > Refund</Link>
          </Box>  
          <Box className="copyright-content" sx={{ textAlign: 'center', pb:2 }}>
              <Typography sx={{ color: '#686868' }}>Copyright © 2024. All Rights Reserved</Typography>
          </Box>
        </Box>
    </>
  )
}