export const GETDONATION            = "[donation] Donation";
export const GETDONATION_SUCCESS    = "[donation] Donation success";
export const GETDONATION_ERROR      = "[donation] Donation error";
export const SETDONATION            = "[donation] Donation set";

export const GETDONATIONDETAILS  = "[Donationdetails] Donationdetails";
export const GETDONATIONDETAILS_SUCCESS = "[Donationdetails] Donationdetails Success";
export const GETDONATIONDETAILS_ERROR = "[Donationdetails] Donationdetails error";
export const SETDONATIONDETAILS  = "[Donationdetails] Donationdetails set";
export const SETDONATIONBOOKINGDETAILS  = "[DONATIONBOOKINGDETAILS] DonationsBookingdetails set";

export const DONATIONPAYMENTPROCESS = "[Donation PAYMENT PROCESS]";
export const DONATIONPAYMENTSUCCESS   = "[Donation PAYMENT SUCCESS] GET TRANS,SIGN,ORDER";
export const DONATIONPAYMENTERROR = "['PAYMENT FAIL']";

export const GETPAYMENT = "[Donation PAYMENTDETAILS]";
export const GETPAYMENTERROR =   "[ Donation PAYMENTDETAILS ERROR]";
export const GETPAYMENTSUCCESS =   "[ Donation PAYMENTDETAILS SUCCESS]";
export const SETPAYMENT =  "[PAYMENTDETAILS SET]";


export const DONATIONCOMPLETE = "[DONATION COMPLETE]";
export const DONATIONCOMPLETEERROR = "[DONATION COMPLETE ERROR]";
export const DONATIONCOMPLETESUCCESS = "[DONATION COMPLETE SUCCESS]";



//Donation list

    export const getDonation = (data) => ({
        type: GETDONATION,
        payload: data
    });
    export const getDonationSuccess = (data) => ({
        type: GETDONATION_SUCCESS,
        payload: data
    });
  
    export const getDonationError = (data) => ({
        type: GETDONATION_ERROR,
        payload: data
    });

    export const setDonation = (data) => ({
        type: SETDONATION,
        payload: data
    });


//Donation details

    export const getDonationdetails = (data) => ({
        type : GETDONATIONDETAILS,
        payload : data,
    });

    export const getDonationdetailsSuccess = (data) => ({
        type: GETDONATIONDETAILS_SUCCESS,
        payload : data,
    });

    export const getDonationdetailsError  = (data) => ({
        type : GETDONATIONDETAILS_ERROR,
        payload : data,
    });

    export const setDonationdetails = (data) => ({
        type : SETDONATIONDETAILS,
        payload : data,
    });

    export const setDonationbookingDetails = (data) => ({
        type : SETDONATIONBOOKINGDETAILS,
        payload : data,
    });


    export const donationPaymentProcess = (data) => ({
        type : DONATIONPAYMENTPROCESS,
        payload : data,
      });
    
      export const donationPaymentSuccess = (data) => ({
        type : DONATIONPAYMENTSUCCESS,
        payload : data,
      });
    
      export const donationPaymentError = (data) => ({
        type : DONATIONPAYMENTERROR,
        payload : data,
      });


      export const getdonationpayment = (data) => ({
        type : GETPAYMENT,
        payload : data,
      });
    
      export const setDonationPaymentDetails = (data) => ({
        type : SETPAYMENT,
        payload : data,
      });
       
      export const getDonationPaymentDetailsError = (data) => ({
        type : GETPAYMENTERROR,
        payload : data,
      });
    
      export const getDonationPaymentDetailsSuccess = (data) => ({
        type : GETPAYMENTSUCCESS,
        payload : data,
      })

      export const donationComplete = (data) => ({
        type : DONATIONCOMPLETE,
        payload : data,
      });

      export const donationCompleteSuccess = (data) => ({
        type : DONATIONCOMPLETESUCCESS,
        payload : data,
      });

      export const donationCompleteError = (data) => ({
        type : DONATIONCOMPLETEERROR,
        payload : data,
      });






