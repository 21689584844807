import React from 'react';
import { 
    AppBar, 
    Box, 
    Container, 
    CssBaseline, 
    Divider,
    Toolbar,
    Typography
} from '@mui/material';
import '../HeaderComponent/HeaderComponent.css';

import { Link } from 'react-router-dom';

import { useLocation } from "react-router-dom";

export default function HeaderComponent(props) {
    const IconEmail = require('../../assets/icon-email.png');
    const IconPhone = require('../../assets/iocn-phone.png');
    const SiteLogo = require('../../assets/logo.png');

    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

  return (
    <>
        <Box>
            <CssBaseline />
            <AppBar component="nav" color="transparent" elevation={0} sx={{ position: 'relative' }}>
                <Container>
                    {/* Top header section */}
                    <Box className="top-header" sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '56px' }}>
                        <Link className='email-link' to="mailto:sridevitemple@gmail.com">
                            <Box>
                                <img 
                                    className='icon-email'
                                    src={String(IconEmail)}
                                    alt="icon-email"
                                />
                            </Box>
                            <Box>
                                sridevitemple@gmail.com
                            </Box>
                        </Link>
                        <Typography className='contact-number' >
                        <Box>
                            <img 
                                className='icon-phone'
                                src={String(IconPhone)}
                                alt="icon-phone"
                            />
                        </Box>
                        <Box>
                        <Typography sx={{ color: '#3875BF' }}>
                        9962514134 
                        </Typography>
                        </Box>
                        </Typography>
                    </Box>
                </Container>
            </AppBar>

            <Divider />

            {/* Main header section */}
            <AppBar className='header-nav' component="nav" color="transparent" elevation={0} sx={{ position: 'relative' }}>
                <Container>
                    <Toolbar disableGutters sx={{ flexGrow : 1, justifyContent : 'space-between', alignItems : 'center' }}>
                        <Box component="div">
                            <Link to="/">
                                {/* <img 
                                    className='site-logo'
                                    src={String(SiteLogo)}
                                    alt="Site-logo"
                                /> */}
                                <h1>Welcome</h1>
                            </Link>                    
                        </Box>
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <ul>
                                <li className='header-main-menu' >
                                <Link exact to="/" className={splitLocation[1] === "/" ? "active" : ""}>
                                    Home
                                </Link>
                                </li>
                                <li className='header-main-menu'>
                                <Link to="/puja-gallery"  className={splitLocation[1] === "puja-gallery" ? "active" : ""}>
                                    Book Puja
                                </Link>
                                </li>
                                 {/* <li className='header-main-menu'> 
                                <Link to="/get-books"  className={splitLocation[1] === "get-books" ? "active" : ""}>
                                    Get Books
                                </Link>
                                </li> */}
                                <li className='header-main-menu'>
                                <Link to="/make-a-donation"  className={splitLocation[1] === "make-a-donation" ? "active" : ""}>
                                    Make a Donation
                                </Link>
                                </li> 
                            </ul>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Divider />
        </Box>
    </>
  )
}
