import {pujaReducer} from "./pujaReducer";
import { donationReducer } from "./donationReducer";
import notificationReducer from "./notificationReducer";
import { combineReducers } from "redux";


export default combineReducers({
  puja: pujaReducer,
  donation: donationReducer,
  notification: notificationReducer
});
