import {produce} from "immer";
import { SETDONATION, SETDONATIONDETAILS,SETDONATIONBOOKINGDETAILS, SETPAYMENT } from "../actions/donation";


const INITIAL_STATE =  {
    donationList:[],
    donationDetails:[],
    donationBookingDetails:[],
    donationPaymentDetails : [],
};

export const donationReducer = produce((draft, action) => {
    switch (action.type) {
        case SETDONATION:
            draft.donationList = action.payload;
        break;
        case SETDONATIONDETAILS : 
            draft.donationDetails = action.payload;
        break;
        case SETDONATIONBOOKINGDETAILS : 
            draft.donationBookingDetails = {...draft.donationBookingDetails, ...action.payload};
        break;
        case SETPAYMENT:
            draft.donationPaymentDetails = action.payload;
        break;
        default:
        return draft;
    }


}, INITIAL_STATE);