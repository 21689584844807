import React from 'react';
import { 
    Box, 
    FormControl, 
    Grid, 
    TextField, 
    Typography,
    InputLabel,
    FormHelperText
} from '@mui/material';
import '../MakeMyDonationComponent/MakeMyDonationComponent.css';
import {getDonationdetails} from '../../store/actions/donation.js';
import { connect } from "react-redux";
import { useImmer } from 'use-immer';

const  MakeMyDonationComponent = ({

    Donationdetails,
    getuserDonationdetails,
    setuserDonationdetails



}) => {

    const ErrorInitialState = {
        error: false,
        message:""
    }

    const [isNameSelected, setIsNameSelected] = useImmer(false);
    const [isContactNumberValid, setIsContactNumberValid] = useImmer(true);
    const [AltContactPersonError, setPersonAltContactPersonError] = useImmer(ErrorInitialState);
    const [MailPersonPinCodeError , setMailPersonPinCodeError] = useImmer(false);
    const [DonationID, setDonationID] = useImmer('');

    React.useEffect(() => {
        setDonationID(Donationdetails.DonationID);

    }, [Donationdetails])


//    const handlePersonName = (event) => {
//         let data = event.target.value;
    
//        // Allow only letters and remove spaces or special characters
//         data = data.replace(/[^a-zA-Z]/g, ""); // Modify the regex as needed
    
//         setuserDonationdetails((draft) => {
//             draft.donationUserName.value = data;
//             draft.donationUserName.isValid = data ? true : false;
//         });
//     }; 

const handlePersonName = (event) => {
    let data = event.target.value;

    // Trim leading spaces and allow Unicode letters, numbers, spaces, parentheses, and combining marks
    data = data
        .trimStart()
        .replace(/[^\p{L}\p{N}\p{M} ()]/gu, ""); // Include combining marks

    setuserDonationdetails((draft) => {
        draft.donationUserName.value = data;
        draft.donationUserName.isValid = data.trim() ? true : false; // Prevent spaces-only inputs
    });
};



    const handleAmount = (event) => {
        let amount = event.target.value.trim();
    
        // Ensure only numeric input is allowed and restrict to 6 digits
        const isNumeric = /^[0-9]*$/;
        if (isNumeric.test(amount) && amount.length <= 6) {
            setuserDonationdetails((draft) => {
                draft.Amount.value = amount;
    
                const amountNumber = parseInt(amount, 10);
    
                // Validate if the amount is within valid range
                if (amount === "" || amountNumber <= 0) {
                    draft.Amount.isValid = false;
                    draft.Amount.errorMesssage = "Please enter Donation Amount";
                } else if (amountNumber > 100000) {
                    draft.Amount.isValid = false;
                    draft.Amount.errorMesssage = "Amount should not exceed 100,000";
                } else {
                    draft.Amount.isValid = true;
                    draft.Amount.errorMesssage = "";
                }
            });
        }
    };


   
    const handlePersonContactNumber = (event) => {
        const contactNumber = event.target.value.trim();
        // Allow numbers, spaces, and characters + - ( )
        const validPattern = /^[0-9+\-() ]*$/;
    
        if (validPattern.test(contactNumber)) {
            setuserDonationdetails((draft) => {
                draft.donationUserContact.value = contactNumber;
                // Basic validation: At least 10 characters excluding invalid ones
                draft.donationUserContact.isValid = contactNumber.replace(/[^0-9]/g, '').length >= 10;
            });
        }
    };
    

    const handlePersonAltNumber = (event) => {
        const alternateNumber = event.target.value.trim();
    
        // Allow only valid characters
        const validPattern = /^[0-9+\-() ]*$/;
    
        if (validPattern.test(alternateNumber)) {
            setuserDonationdetails((draft) => {
                draft.donationUserAlt.value = alternateNumber;
    
                // Extract numeric parts for comparison
                const numericAltNumber = alternateNumber;
                console.log(numericAltNumber);
                const numericContactNumber = draft.donationUserContact.value;
                console.log(numericContactNumber);
    
                // Validation logic
                if (numericAltNumber.length === 0) {
                    draft.donationUserAlt.isValid = true; // Empty is valid
                } else if (numericAltNumber !== numericContactNumber) {
                    draft.donationUserAlt.isValid = true; // Valid if different from contact number
                } else {
                    draft.donationUserAlt.isValid = false; // Invalid if same as contact number
                }
            });
        }
    };
        // const handleMailPersonName = (event) => {
        //     setuserDonationdetails((draft) => {
        //     draft.donationemailName.value= event.target.value;
        //     })
        // };

        const handleMailPersonAddress = (event) => {
            const value = event.target.value;
            // Regex to allow valid characters including #
            const validAddressRegex = /^[\p{L}\p{N}\p{M}\s,.'-/&]*$/u;
            if (validAddressRegex.test(value) || value === '') {
                setuserDonationdetails((draft) => {
                    draft.donationemailAddress.value = value;
                });
            }
        };
       

        const handleMailPersonPinCode = (event) => { 
            const pincode = event.target.value;
            const regex = /^[a-zA-Z0-9]*$/; // Allow alphanumeric characters
        
            setuserDonationdetails((draft) => {
                draft.donationemailPincode.value = pincode;
            });
        
            if (pincode.length === 0 || pincode.length > 10) {
                // Clear error when the input is empty or exceeds 10 characters
                setMailPersonPinCodeError(false);
            } else if (!regex.test(pincode)) {
                // Show error for invalid characters
                setMailPersonPinCodeError(true);
            } else {
                // Hide error when valid input is within 10 characters
                setMailPersonPinCodeError(false);
            }
        };
        
        
        


  return (
    <>
        <Box
            className='donation-booking'
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1 },
            mx: 20,
            pt: 6
            }}
            noValidate
            autoComplete="off"
        >
            <div>
            <Box sx={{ mb: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <label>Purpose</label>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body1">{Donationdetails.DonationName}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
                <Box sx={{ mb: 4 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <label>Amount <span className="label-required">*</span></label>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <FormControl fullWidth>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            type="number"
                            inputMode="numeric"
                            value={getuserDonationdetails.Amount.value}
                            onChange={handleAmount}
                            inputProps={{ maxLength: 6 }}
                        />
                    </FormControl>
                    {/* Show error messages based on validation */}
                    { !getuserDonationdetails.Amount.isValid && (
                        <FormHelperText error>
                            {getuserDonationdetails.Amount.errorMesssage}
                        </FormHelperText>
                    )}
                </Grid>
            </Grid>
        </Box>
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Your Name  <span className="label-required">*</span></label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField 
                                    id="outlined-basic" 
                                    variant="outlined" 
                                    value={getuserDonationdetails.donationUserName.value} 
                                    onChange={handlePersonName} 
                                />
                            </FormControl>
                                {getuserDonationdetails.donationUserName.value == "" && (
                                <FormHelperText error> 
                                    Please enter a valid Name
                                </FormHelperText>
                                )}
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>
                                    Your Contact WhatsApp# <span className="label-required">*</span>
                                </label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    type="text"
                                    inputMode="tel"
                                    value={getuserDonationdetails.donationUserContact.value}
                                    onChange={handlePersonContactNumber}
                                />
                            </FormControl>
                            {!getuserDonationdetails.donationUserContact.isValid && (
                                <FormHelperText error>
                                    Please enter a valid Contact number.
                                </FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                </Box>
                        <Box sx={{ mb: 4 }}>
                        <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Box>
                        <label>Alternate Mobile No#</label>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <FormControl fullWidth>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            type="text"
                            value={getuserDonationdetails.donationUserAlt.value}
                            onChange={handlePersonAltNumber}
                            error={!getuserDonationdetails.donationUserAlt.isValid}
                        />
                        {!getuserDonationdetails.donationUserAlt.isValid && (
                            <FormHelperText error>
                                Alternate number should not be the same as the contact number.
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
                    <Box>
                    <Typography sx={{ pb: 4, fontWeight: '600', color: '#010101' }}>Your Address</Typography>
                    {/* <Box sx={{ mb: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <label>Name</label>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    <TextField 
                                        id="outlined-basic" 
                                        variant="outlined" 
                                        value={getuserDonationdetails.donationemailName.value} 
                                        onChange={handleMailPersonName}  
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box> */}
                    <Box sx={{ mb: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <label>Address</label>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    <TextField multiline
                                        rows = "4" 
                                        id="outlined-basic" 
                                        variant="outlined" 
                                        value={getuserDonationdetails.donationemailAddress.value} 
                                        onChange={handleMailPersonAddress}   
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ mb: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <label>Pin Code</label>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    <TextField 
                                        id="outlined-basic" 
                                        variant="outlined"  
                                        type="text" 
                                        value={getuserDonationdetails.donationemailPincode.value} 
                                        onChange={handleMailPersonPinCode} 
                                        error = {MailPersonPinCodeError}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </Box>
    </>
  )
}

const mapStateToProps = (state) => {
    return {
        Donationdetails: state.donation.donationDetails        
    };
}


export default connect(mapStateToProps,{
    getDonationdetails,
    
  })(MakeMyDonationComponent);
