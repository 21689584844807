import React from 'react';
import { useEffect } from "react";
import { connect } from "react-redux";
import { 
    Box, 
    Container, 
    Grid, 
    Typography,
    CircularProgress,
} from '@mui/material';
import { useImmer } from 'use-immer';
import { useNavigate } from "react-router-dom";
import '../DonationGalleryComponent/DonationGalleryComponent.css';
import NoDataFound from '../../assets/no-data-found.png';
import * as config from "../../assets/js/constant.js";
import {getDonation} from '../../store/actions/donation.js';


const DonationGalleryComponent = ({
    getDonation,
    donationList
  

  }) => {

    const navigate = useNavigate();
    const [getloader, setloader] = useImmer(true);
    const [dataFetched, setDataFetched] = useImmer(false);

    
    // useEffect(() => {
    //   var donationData = getDonation();
    //   }, [])


      useEffect(() => {
        const fetchData = async () => {
            setloader(true);  // Start loader immediately
            await getDonation();  // Fetch data
            setTimeout(() => {
                setloader(false);  // Stop loader after 2 seconds
                setDataFetched(true); // Mark data as fetched after loader
            }, 2000);  // 2 seconds delay
        };
        
        fetchData();  // Call fetchData
    }, [getDonation]);
    console.log(donationList);
    function onClickDonation(id) {
      navigate(`/make-a-donation/my-donation/${id}`);
  }

  return (
    <>
        <Box className="donation-gallery-page">
            <Container sx={{ px: 1 }}>
            <Box sx={{ py: 2, width: 'max-content' }}>
                    <Typography sx={{ backgroundColor: '#686868', padding: '6px 12px', borderRadius: '40px', color: '#ffffff' }}>Donation Gallery</Typography>
                </Box>
                {getloader ? (
                    // Show loader while fetching data
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                        <CircularProgress />
                    </Box>
                ) : (

                      dataFetched && donationList.length > 0 ? (
                        <Box>
                            <Grid container spacing={1}>
                            {donationList.map((donation, index) => (
                                <Grid item xs={6} md={2.4} key={index}>
                                    <Box
                                         onClick={() => onClickDonation(donation.DonationID)}
                                        className="donation-gallery-section"
                                        sx={{ backgroundColor: '#ffffff', textAlign: 'center', py: 1, px: 1, borderRadius: '4px' }}
                                    >
                                        <img
                                            className='donation-image'
                                            src={config.image + donation.Image}
                                            alt="donationImage"
                                        />
                                        <Box sx={{ textAlign: 'center', py: 1.5 }}>
                                            <Typography className='donationgallery-name'>
                                                {donation.DonationName}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                ))}
                                </Grid>
                            </Box>
                        ) : (
                            // <Typography>No Donation found</Typography>
                            <Box className="noDonationFound" sx={{mb: 5}}>
                                <img
                                className='noDataFound'
                                src={NoDataFound}
                                alt="nodatafound"
                                />
                                <Typography sx={{ color: '#8E8E8E', fontSize: '1.2rem', fontWeight: 500 }}>No Donation found!</Typography>
                            </Box>

                        )
                )}
            </Container>
        </Box>
    </>
  )
};
const mapStateToProps = (state) => {
  return{
      donationList : state.donation.donationList

  };

 }
export default connect(mapStateToProps,{
    getDonation,
 })(DonationGalleryComponent);
