import React from 'react';
import { connect } from "react-redux";
import { getdonationpayment } from '../../store/actions/donation.js';
import { 
    Box, 
    FormControl, 
    Grid, 
    TextField, 
    Typography,
    FormHelperText
} from '@mui/material';


const DonationPaymentDetailsComponent =  ({
    getDonationPaymentDetails,
    setDonationPaymentDetails,
    Amount,
    
})  => {
    
    console.log("AMount:",Amount)

    const ErrorInitialState = {
        error: false,
        message:""
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handledonationEmail = (event) => {
      const email = event.target.value.trim().toLowerCase();
    
      setDonationPaymentDetails((draft) => {
        draft.donationUserEmail.value = email;
    
        if (emailRegex.test(email)) {
          draft.donationUserEmail.isValid = true; // Mark as valid
          draft.donationUserEmail.error = ""; // Clear error message
        } else {
          draft.donationUserEmail.isValid = false; // Mark as invalid
          draft.donationUserEmail.error = "Invalid email format"; // Set error message
        }
      });
    };

    const handledonationComments = (event) => {
        const Comments = event.target.value;
        setDonationPaymentDetails((draft) => {
            draft.userComments.value = Comments;
            // Validate only if comments exceed 120 characters
            draft.userComments.isValid = Comments.length <= 120;
          });
    }

return (
    <>
        <Box
            className='donation-confirmation'
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1 },
            mx: 20,
            pt: 6
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Amount Paid</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {/* <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined"  />
                            </FormControl> */}
                            <Typography sx={{ color: '#010101',fontWeight: '600', pl: 1 }}>&#x20b9; {Amount}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Payment RER/UTR</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {/* <FormControl fullWidth>
                                <TextField id="outlined-basic" variant="outlined"  />
                            </FormControl> */}
                            <Typography sx={{ color: '#010101',fontWeight: '600', pl: 1  }}>{getDonationPaymentDetails.OrderID.value}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                    <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                        <Box>
                            <label>
                            Your Email ID <span className='label-required'>*</span>
                            </label>
                        </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                        <FormControl fullWidth>
                            <TextField
                            id="outlined-basic"
                            variant="outlined"
                            value={getDonationPaymentDetails.donationUserEmail.value}
                            onChange={handledonationEmail}
                            />
                            {!getDonationPaymentDetails.donationUserEmail.isValid && (
                            <FormHelperText error>
                                {getDonationPaymentDetails.donationUserEmail.errorMessage}
                            </FormHelperText>
                            )}
                        </FormControl>
                        </Grid>
                    </Grid>
                    </Box>
                <Box sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Comments</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    multiline
                                    rows={4}
                                    value={getDonationPaymentDetails.userComments.value}
                                    onChange={handledonationComments}
                                    error = {!getDonationPaymentDetails.userComments.isValid}
                                />
                                <span>{getDonationPaymentDetails.userComments.value.length}/120</span>
                                {
                                    !getDonationPaymentDetails.userComments.isValid && (
                                        <FormHelperText error>
                                            {getDonationPaymentDetails.userComments.errorMessage}
                                        </FormHelperText>

                                    )
                                }
                                
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </Box>
    </>
  )
}

const mapStateToProps = (state) => {
    return {
        donationPaymentDetails : state.donation.donationPaymentDetails,
    };
}
 

  export default connect(mapStateToProps,{
    getdonationpayment
    
  })(DonationPaymentDetailsComponent);