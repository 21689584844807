import React from 'react';
import { connect } from "react-redux";

import {
    Box, 
    FormControl, 
    FormControlLabel, 
    Grid,
    InputLabel,
    Radio, 
    RadioGroup, 
    TextField, 
    FormHelperText ,
    InputAdornment,
    OutlinedInput
   
} from '@mui/material';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useImmer } from 'use-immer';
import {getpujadetail} from '../../store/actions/puja.js';


import '../PujaHomaBookingComponent/PujaHomaBookingComponent.css';

const PujaHomaBookingComponent =  ({
    Pujadetails,
    getpujahomadetails,
    setpujahomadetails,
    // resetValidationErrors
}) => {
console.log(getpujahomadetails);
    const ErrorInitialState = {
        error: false,
        message:""
    }

    const [dateError, setDateError] = useImmer(true);
    const [personError, setPersonError] = useImmer(true);
    const [detailError, setDetailError] = useImmer(ErrorInitialState);
    const [isRadioSelected, setIsRadioSelected] = useImmer(false);
    const [isAmount,setAmount] = useImmer(true);


    // useEffect(() => {
    //     resetValidationErrors(); // Reset validation errors when moving back
    // }, []);

   

    const handleDateChange = (date) => {
        if(date){
            setpujahomadetails((draft) => {
                const formattedDate = dayjs(date).format('DD-MM-YYYY');
                draft.pujaDate = formattedDate
            })
            setDateError(false);
             
         }
         else{
            setDateError(true);
         }
    };


    const handleAmount = (event) => {
        const currency = event.target.value.trim();
        const amount = parseInt(currency);
        setpujahomadetails((draft)=>{
            draft.Amount = currency;
        })
        if (amount < Pujadetails.Amount) {
            setAmount(false); // Less than 100, set error to true
        } else {
            setAmount(true); // Valid amount, set error to false
        } 
    }

    const handlePerson = (event) => {
        setpujahomadetails((draft) => {
            draft.pujaPerson = event.target.value;
        })
        setIsRadioSelected(true); 
    };

    const handleDetails = (event) => {
        const sankalpamDetails = event.target.value;
        if (sankalpamDetails.length <= 300) {
            setpujahomadetails((draft) => {
                draft.pujaDetail = sankalpamDetails;
            });
            setDetailError((draft) => {
                draft.error = false;
                draft.message = "";
            });
        } else {
            setDetailError((draft) => {
                draft.error = true;
                draft.message = "Sankalpam Details should not exceed 300 characters";
            });
        }
    };
  return (
    <>
        <Box
            className='puja-homa-booking'
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1 },
            mx: 20,
            pt: 6
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <Box className="form-control-spacing">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Select Puja/Homa <span className='label-required'>*</span></label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Box sx={{ position: 'relative' }}>
                                <FormControl fullWidth size="small">
                                    <InputLabel htmlFor = "Selectedpujaname"></InputLabel>
                                    <TextField 
                                      inputProps={{ readOnly: true }}
                                      labelId="demo-select-small-label"
                                      id="Selectedpujaname"
                                      value={Pujadetails.PujaName}
                                        >
                                      </TextField>  
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="form-control-spacing">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Preferred Date  <span className='label-required'>*</span></label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth sx={{ margin: '0 !important' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Box
                                        sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        position: 'relative',
                                        }}
                                    >
                                        <MobileDatePicker
                                        disablePast 
                                        minDate={dayjs().add(2, 'day')}
                                        value={dayjs(getpujahomadetails.pujaDate, 'DD-MM-YYYY')} 
                                        format="DD-MM-YYYY"
                                        onChange={handleDateChange}
                                         onKeyDown={(e) => {
                                        e.preventDefault();
                                        }}
                                        renderInput={(params) => <TextField {...params} />} 
                                        sx={{ width: '100%',border: 'none !important'  }}
                                        />
                                    </Box>
                                </LocalizationProvider>
                            </FormControl>

                            {getpujahomadetails.pujaDate === "" && (
                            <FormHelperText error>Please select a date</FormHelperText>
                                )}
                        </Grid>
                    </Grid>
                </Box>
            <Box className = "form-control-spacing">
                <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                        <Box>
                            <label>Amount <span className='label-required'>*</span></label>
                        </Box>
                        </Grid>
                    <Grid item xs={12} sm={8}>
                        <FormControl fullWidth sx={{ m: 1 }}>
                            <InputLabel htmlFor="outlined-adornment-amount"></InputLabel>
                            <OutlinedInput
                             type="number"
                                id="outlined-adornment-amount"
                                onChange = {handleAmount}
                                value={getpujahomadetails.Amount}
                                startAdornment={<InputAdornment position="start">INR</InputAdornment>}
                                inputProps={{ min: Pujadetails.Amount}} 
                            />
                            {!isAmount && (
                            <FormHelperText error> Minimum Amount {Pujadetails.Amount}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
                <Box className="form-control-spacing">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Attend in Person?  <span className='label-required'>*</span></label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl sx={{ m: 0 }} fullWidth>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={getpujahomadetails.pujaPerson}
                                    onChange = {handlePerson}
                                    error={!isRadioSelected} 
                                >
                                    <FormControlLabel value="Yes" control={<Radio />}  label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                                {getpujahomadetails.pujaPerson == "" && (
                                <FormHelperText error>Please select an option</FormHelperText>
                                 )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="form-control-spacing">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box>
                                <label>Sankalpam & Other Details</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    multiline
                                    rows={4}
                                    value={getpujahomadetails.pujaDetail}
                                    onChange = {handleDetails}
                                />
                                <span>{getpujahomadetails.pujaDetail.length}/300</span>
                                 {detailError.error && (
                                    <FormHelperText error>{ detailError.message }</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </Box>
    </>
  )
}

const mapStateToProps = (state) => {
    return {
        Pujadetails: state.puja.pujadetails
        
    };
}

export default connect(mapStateToProps,{
    getpujadetail,
    
  })(PujaHomaBookingComponent);
