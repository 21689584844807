import React from 'react';
import { connect } from "react-redux";
import { 
    Box,
    Typography 
} from '@mui/material';
import '../ConfirmMyDonationComponent/ConfirmMyDonationComponent.css';
import { setDonationdetails } from '../../store/actions/puja';

const ConfirmMyDonationComponent =  ({
    donationBookingDetails,
    
}) => {
    console.log(donationBookingDetails);

  return (
    <>
        <Box
            className='donation-confirmation'
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1 },
            mx: 20,
            pt: 6
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <Box className="form-control-spacing" sx={{ display: 'flex' }}>
                    <Typography sx={{ pb: 4, color: '#8E8E8E' }}>You  wish to make a contribution of</Typography>
                    <Typography sx={{fontWeight: '600', pl: 1, color: '#010101' }}>&#x20b9;{donationBookingDetails.Amount} </Typography>
                    <Typography sx={{ pl: 1, color: '#8E8E8E' }}> to </Typography> 
                    <Typography sx={{fontWeight: '600', pl: 1, color: '#010101' }}>{donationBookingDetails.donationName}</Typography>
                    {/* <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box className="label-donation-confirm">
                                <label>Purpose</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography sx={{ color: '#8E8E8E' }}>Dasha Dhanam</Typography>
                        </Grid>
                    </Grid> */}
                </Box>
                {/* <Box className="form-control-spacing">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box className="label-puja-confirm">
                                <label>Amount</label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography sx={{ color: '#8E8E8E' }}>20,000</Typography>
                        </Grid>
                    </Grid>
                </Box> */}
            </div>
        </Box>
    </>
  )
}

const mapStateToProps = (state) => {
    return{
        donationBookingDetails: state.donation.donationBookingDetails,
    };
  
   }

export default connect(mapStateToProps,{
    
})(ConfirmMyDonationComponent);
